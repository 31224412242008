<template>
  <div>
    <CButton
        v-if="btnContinueTenderWithComment()"
        color="success"
        v-bind="$attrs"
        class="sideBar_btn"
        @click="showModal(true)"
    >
      Прошу продовжити тендер
    </CButton>
    <CModal
        color="info"
        title="Продовження тендера"
        :show.sync="showModalContinueTender"
        size="lg"
        addContentClasses="srl"
    >
      <template #body-wrapper>
        <CCardHeader class="">
          <h4>Вкажіть свої зауваження чи пропозицію:</h4>
          <CAlert v-if="err.length" class="m-0" color="danger">
            <ul class="text-decoration-none">
              <li v-for="error in err" :key="error">{{ error }}</li>
            </ul>
          </CAlert>
        </CCardHeader>
        <CCardBody class="p-1">
          <CTextarea
              label="Продовження тендера"
              placeholder="Напишіть свої міркування"
              cols="15"
              rows="3"
              class="m-2"
              v-model="comment"
          ></CTextarea>
        </CCardBody>
      </template>
      <template #footer>
        <CButton
            @click="setITRPProposal()"
            color="info">
          Продовжити
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import {can} from "@/rules/permissions";
import axios from "axios";

export default {
  props: {
    order: {
      type: Object,
      default: undefined,
      required: true
    }
  },
  data() {
    return {
      showModalContinueTender: false,
      comment: '',
      err: []
    }
  },
  methods: {
    can,
    btnContinueTenderWithComment() {
      return this.can('head_continue_tender') && ['ConfirmITRP', 'ConfirmMainOffice'].includes(this.$props.order.status);
    },
    showModal(val = false) {
      this.showModalContinueTender = val;
    },

    setITRPProposal() {
      let self = this;
      let axios_send = '';

      if (!self.comment) {
        return this.err.push('Заповніть поле із коментарем!')
      }

      if (this.$props.order.status === 'ConfirmITRP') {
        axios_send = `/api/orders/${self.$route.params.id}/offers/proposal/winner`
      }
      if (this.$props.order.status === 'ConfirmMainOffice') {
        axios_send = `/api/orders/${self.$route.params.id}/offers/main/proposal/winner`
      }

      self.comment = `Прошу продовжити тендер. ${self.comment}`


      axios.post(axios_send, {
        success: false,
        comment: self.comment,
        offer_id: ''
      })
          .then(function () {
            // self.getTenderByID();
            self.showModal();
            self.comment = "";
            self.$router.push({path: `/tenders/${self.$route.params.id}`});
          })
          .catch(function () {
            self.err.push(`Сталася помилка, повторіть дію пізніше`)
            setTimeout(() => {
              self.showModal();
              self.comment = "";
              self.err = [];
            }, 2000);

          })
    },
  }
}
</script>