<template>
  <CDataTable
      v-if="$props.offers.length"
      hover
      striped
      :items="$props.offers"
      :fields="$props.isShowFeildsWin ? fieldsAllOffersWin : ($props.order.status === 'CloseWithResult') ?  fieldsAllOffersClose : fieldsOffersWithoutWin"
      :pagination="$options.paginationProps"
      :sorter-value="{column: 'proposal_result', asc: true}"
      :table-filter="{lazy: false, placeholder: 'Знайти...', label: ' '}"
      index-column
      :noItemsView="{ noResults: 'За результатами пошуку нічого не знайдено', noItems: 'no items available' }"
      clickable-rows
      sorter
  >
    <template #provider_brand="data">
      <td>
        {{ data.item.brand }}
      </td>
    </template>
    <template #provider_name="data">
      <td>
        {{ data.item.name }}
      </td>
    </template>
    <template #connection_cost="data">
      <td>
        {{ data.item.status === 'NoTechnicalPossibility' ? " " : data.item.connection_cost }}
      </td>
    </template>
    <template #monthly_payment="data">
      <td>
        {{ data.item.status === 'NoTechnicalPossibility' ? " " : data.item.monthly_payment }}
      </td>
    </template>
    <template #speed="data">
      <td>
        {{
          data.item.status === 'NoTechnicalPossibility' ? " " : `${data.item.download_speed}/${data.item.upload_speed}`
        }}
      </td>
    </template>
    <template #days_connect="data">
      <td>
        {{ data.item.status === 'NoTechnicalPossibility' ? " " : data.item.days_to_connect }}
      </td>
    </template>
    <template #show_more="data">
      <td>
        <CButton
            color="primary"
            variant="outline"
            size="sm"
            @click="showDetails(data.item.id)"
        >
          Показати
        </CButton>
      </td>
    </template>
    <template #protocol_id="data">
      <td>
        {{
          data.item.status === 'NoTechnicalPossibility' ? " " : $store.getters.getProtocolByID(data.item.protocol_id)
        }}
      </td>
    </template>
    <template #iface_id="data">
      <td>
        {{
          data.item.status === 'NoTechnicalPossibility' ? " " : $store.getters.getInterfaceByID(data.item.iface_id)
        }}
      </td>
    </template>
    <template #physics_id="data">
      <td>
        {{
          data.item.status === 'NoTechnicalPossibility' ? " " : $store.getters.getPhysicsByID(data.item.physics_id)
        }}
      </td>
    </template>
    <template #proposal_result="data">
      <td>{{ data.item.status === 'NoTechnicalPossibility' ? " " : data.item.proposal_result }}</td>
    </template>
    <template #changeWINNER="data">
      <td>
        <CButton
            data-cy="AllOffersGridChooseWinner"
            class="p-1 text-center"
            :color="data.item.status === 'NoTechnicalPossibility'? 'warning' : data.item.status === 'Refusal' ? 'dark' : getChangeColorBlocked($props.providers.is_blocked)"
            :disabled="$props.disabledWinnerBtn || data.item.status === 'NoTechnicalPossibility' || data.item.status === 'Refusal'"
            @click="clickBtnCheckWinner(data.item)"
        >
          {{
            data.item.is_verify ? "Вибрати Переможця" : data.item.status === 'NoTechnicalPossibility' ? 'Немає Технічної Можливості' : data.item.status === 'Refusal' ? 'Не виконав підключення' : "Вибрати Переможця"
          }}
        </CButton>
      </td>
    </template>
    <template #isWINNER="data">
      <td>
        <CBadge class="p-1" :color="getBadgeStatusOrder(data.item.status)">
          {{ statusOrders(data.item.status) }}
        </CBadge>
      </td>
    </template>
  </CDataTable>
</template>
<script>
import mixin from "@/mixins/statusTenderAndPropositionMixin";
import {fieldsAllOffersWin, fieldsAllOffersClose, fieldsOffersWithoutWin} from './Fields';
import {routerGoToName} from "@/utils/routerUtils";

export default {
  mixins: [mixin],
  props: {
    offers: {
      type: [Array],
      default: undefined,
      required: true
    },
    providers: {
      type: [Array],
      default: undefined,
      required: true
    },
    order: {
      type: [Object],
      default: undefined,
      required: true
    },
    isShowFeildsWin: {
      type: [Boolean],
      default: false,
      required: true
    },
    disabledWinnerBtn: {
      type: [Boolean],
      default: false,
      required: true
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next',
  },
  data: () => {
    return {
      fieldsAllOffersWin,
      fieldsAllOffersClose,
      fieldsOffersWithoutWin
    }
  },
  created() {
    if (!this.$store.getters.getPhysicsLength) this.$store.dispatch('GET_Physics');
    if (!this.$store.getters.getInterfaceLength) this.$store.dispatch('GET_Interface');
    if (!this.$store.getters.getProtocolLength) this.$store.dispatch('GET_Protocol');
  },
  methods: {
    clickBtnCheckWinner(data) {
      this.$emit('clickBtnCheckWinner', data)
    },
    getChangeColorBlocked(is_blocked) {
      return !is_blocked ? 'danger' : 'info'
    },
    showDetails(item) {
      routerGoToName('TendersViewOfferDetail', 'params', {offers_id: item.toString()})
      // this.$router.push(`/offer/${item.toString()}`)
    },

  }
}
</script>