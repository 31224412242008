<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol>
              <h3>Пропозиції по тендеру "{{ this.$route.params.id }}"</h3>
              <p><span class="font-weight-bold">{{ order.branch_a }}</span> {{ order.branch_a_address }}</p>
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12">
              <template v-if="commentDepartment !== null && commentDepartment.login">
                <p v-if="commentDepartment.provider_name !== ''">
                  Зауваження від <b>{{ commentDepartment.login }}</b>: {{ commentDepartment.comment }}
                </p>
                <p v-else>
                  Зауваження від <b>{{ commentDepartment.login }}</b>: {{ commentDepartment.comment }}
                </p>
              </template>
              <template v-if="commentITRP !== null && commentITRP.login">
                <p v-if="commentITRP.provider_name !== ''">
                  Коментар від <b>{{ commentITRP.login }}</b>: пропонує <b>{{
                    commentITRP.provider_name
                  }}</b>, тому що: {{ commentITRP.comment }}
                </p>
                <p v-else>
                  Коментар від <b>{{
                    commentITRP.login
                  }}</b>: нікого не вибрав, тому що: {{ commentITRP.comment }}
                </p>
              </template>
              <template v-if="commentMAIN !== null && commentMAIN.login">
                <p v-if="commentMAIN.provider_name !== ''">
                  Коментар від <b>{{ commentMAIN.login }}</b>: пропонує <b>{{
                    commentMAIN.provider_name
                  }}</b>, тому що: {{ commentMAIN.comment }}
                </p>
                <p v-else>
                  Коментар від <b>{{
                    commentMAIN.login
                  }}</b>: нікого не вибрав, тому що: {{ commentMAIN.comment }}
                </p>
              </template>
            </CCol>
          </CRow>
        </CCardHeader>
        <modal-any-information
            data-cy="ModalChooseWinner"
            color="success"
            title="Вибрати переможця"
            :show-any-information-modal="isWinnerModal">
          <template v-slot:body>
            <CCardHeader class="">
              <h4>Вкажіть свої зауваження чи пропозицію:</h4>
              <p>Провайдер: '{{ offers_provider }}'</p>
              <CAlert v-if="err.length >0 " class="m-0" color="danger">
                <ul class="text-decoration-none">
                  <li v-for="error in err" :key="error">{{ error }}</li>
                </ul>
              </CAlert>
            </CCardHeader>
            <CCardBody class="p-1">
              <CTextarea
                  data-cy="ModalChooseWinnerTextarea"
                  placeholder="Напишіть, чому ви вибрали цього провайдера?"
                  cols="15"
                  rows="3"
                  class="m-2"
                  v-model="comment"
              ></CTextarea>
            </CCardBody>
          </template>
          <template v-slot:footer>
            <CButton
                data-cy="ModalChooseWinnerBtnSend"
                @click="winnerTender(true,false)"
                color="success">
              Відправити
            </CButton>
          </template>
        </modal-any-information>
        <modal-any-information
            data-cy="ModalCheckConnectPoint"
            color="dark"
            title="Перевірте точку підключення"
            :show-any-information-modal="alertChoiceWinner">
          <template v-slot:body>
            <CCardBody class="p-3">
              <div>
                <p>За адресою: <span class="font-weight-bold">{{ order.branch_a }}</span>, {{ order.branch_a_address }}
                  Ви вибрали провайдера {{ offers_provider }}.<br/> Перевірте:</p>
                <ul>
                  <li>чи немає активного підключення обраного провайдера за даною адресою</li>
                  <li>чи немає незакритих тендерів або інших планових підключень за даною адресою (наприклад,
                    підключення
                    по сз 8540)
                  </li>
                </ul>
              </div>
            </CCardBody>
          </template>
          <template v-slot:footer>
            <CButton
                color="secondary"
                @click="'close'"
                ref="noActiveConnectBack"
            >Назад
            </CButton>
            <CButton
                data-cy="ModalCheckConnectPointTherearentActiveConnections"
                ref="noActiveConnect"
                color="dark">
              Немає активних підключень
            </CButton>
          </template>
        </modal-any-information>
        <CModal
            color="info"
            title="Вибрати переможця тендеру"
            :show.sync="setManagerProposalModal"
            size="lg"
            addContentClasses="srl"
        >
          <template #body-wrapper>
            <CCardHeader class="">
              <h4>Напишіть чому саме цей провайдер:</h4>
              <CAlert v-if="err.length" class="m-0" color="danger">
                <ul class="text-decoration-none">
                  <li v-for="error in err" :key="error">{{ error }}</li>
                </ul>
              </CAlert>
            </CCardHeader>
            <CCardBody class="p-1">
              <CTextarea
                  label="Вибрати переможця"
                  placeholder="Коментар"
                  cols="15"
                  rows="3"
                  class="m-2"
                  v-model="comment"
              ></CTextarea>
            </CCardBody>
          </template>
          <template #footer>
            <CButton
                color="secondary"
                @click="setManagerProposalModal=!setManagerProposalModal"
            >
              Відмінити
            </CButton>
            <CButton
                v-if="loadingsetManagerProposal"
                color="primary"
                class="mb-1 px-5"
                disabled
            >
              <div class="  align-items-center">
                <CSpinner color="default" size="sm"/>
              </div>
            </CButton>
            <CButton
                v-else
                @click="setManagerProposal"
                color="info">
              Підтвердити переможця
            </CButton>
          </template>
        </CModal>
        <CCardBody>
          <template v-if="offers.length">
            <all-offers-grid
                :disabled-winner-btn="disabledWinnerBtn"
                :is-show-feilds-win="is_show_feilds_Win"
                :order="order" :providers="providers" :offers="offers"
                @clickBtnCheckWinner="clickBtnCheckWinner"
            />
          </template>
          <template v-else>
            <h2>Пропозиції ще не надійшли :(</h2>
          </template>
        </CCardBody>
        <CCardFooter>
          <CRow>
            <CCol col="12" xl="4" md="4" lg="4">
              <CButton
                  color="secondary"
                  class="px-4 mr-1"
                  @click="goBack"
              >
                Назад
              </CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
    <my-side-bar>
      <template v-slot:content>
        <div class="sideBarContent">
          <btn-delegate-tender :order="order"/>
          <btn-continue-tender type="continue" :order="order"/>
          <btn-continue-tender-with-comment :order="order"/>
          <btn-close-tender-without-winner :order="order"/>
          <btn-cancel-and-close-tender :order="order" type="close"/>
          <btn-cancel-and-close-tender :order="order" type="cancel"/>
          <btn-remark-department-tender :order="order"/>
        </div>
      </template>
    </my-side-bar>
  </CRow>
</template>

<script>
import axios from "axios";
import mixin from "@/mixins/statusTenderAndPropositionMixin";
import timeConvertMixin from '@/mixins/timeConvertMixin';

import ModalAnyInformation from "~/Components/Modal/ModalAnyInformation.vue";
import ModalDelegatePerson from "~/Components/Modal/ModalDelegatePerson.vue";
import AllOffersGrid from "~/Components/Grid/AllOffersGrid.vue";
import {routerGoToName} from "@/utils/routerUtils";
import BtnDelegateTender from "~/Components/ButtonAndModal/btnDelegateTender.vue";
import BtnContinueTender from "~/Components/ButtonAndModal/BtnContinueTender.vue";
import BtnRemarkDepartmentTender from "~/Components/ButtonAndModal/btnRemarkDepartmentTender.vue";
import BtnCancelAndCloseTender from "~/Components/ButtonAndModal/btnCancelAndCloseTender.vue";
import {can} from "@/rules/permissions";
import BtnContinueTenderWithComment from "~/Components/ButtonAndModal/btnContinueTenderWithComment.vue";
import BtnCloseTenderWithoutWinner from "~/Components/ButtonAndModal/btnCloseTenderWithoutWinner.vue";
import MySideBar from '~/Components/SideBar/MySideBar.vue';

export default {
  mixins: [mixin, timeConvertMixin],
  name: "TenderAllOffers",
  components: {
    BtnCloseTenderWithoutWinner,
    BtnContinueTenderWithComment,
    BtnCancelAndCloseTender,
    BtnRemarkDepartmentTender,
    BtnContinueTender,
    BtnDelegateTender,
    AllOffersGrid,
    ModalDelegatePerson,
    ModalAnyInformation,
    MySideBar
  },
  data: () => {
    return {
      alertChoiceWinner: false,
      loadingsetManagerProposal: false,
      setManagerProposalModal: false,
      commentITRP: null,
      commentMAIN: null,
      commentDepartment: null,
      isWinnerModal: false,
      isWinnerBthSuccess: false,
      disabledWinnerBtn: false,
      is_show_feilds_Win: false,
      order_id: '',
      offers_provider: '',
      offers_id: '',
      comment: "",
      err: [],
      order: {},
      offers: [],
      providers: [],
      perPage: 30,
    }
  },

  methods: {
    can,

    clickBtnCheckWinner(data = null) {
      if (!data) return;
      this.offers_id = data?.id;
      this.offers_provider = data?.name;
      this.winnerTender(false, false);
    },

    getTenderByID() {
      let self = this;
      axios.get(`/api/orders/${this.$route.params.id}`)
          .then(function (response) {
            self.order = response.data;
          })
          .catch(err => console.log(err));
    },

    getAllOffersByOrderID() {
      let self = this;
      axios.get(`/api/orders/${self.$route.params.id.toString()}/offers`)
          .then(resp => resp.data)
          .then(response => {
            if (!response) return self.offers = [];

            self.offers = response.map(item => {
              if (!item.status) return;
              if (item.status === 'NoTechnicalPossibility') item._classes = 'table-warning';
              return item
            });
          }).catch(err => console.log(err))
    },

    goBack() {
      routerGoToName('TendersPreview', 'params', this.$route.params.id);
    },

    async openModalNoActiveConnect() {
      return new Promise((resolve, reject) => {
        this.alertChoiceWinner = true;

        const noActiveConnectClick = () => {
          this.alertChoiceWinner = false;
          cleanup();
          resolve("noActiveConnect");
        };

        const noActiveConnectBackClick = () => {
          this.alertChoiceWinner = false;
          cleanup();
          reject("noActiveConnectBack");
        };
        const cleanup = () => {
          this.$refs.noActiveConnect.removeEventListener('click', noActiveConnectClick);
          this.$refs.noActiveConnectBack.removeEventListener('click', noActiveConnectBackClick);
        };

        this.$nextTick(() => {
          this.$refs.noActiveConnect.addEventListener('click', noActiveConnectClick);
          this.$refs.noActiveConnectBack.addEventListener('click', noActiveConnectBackClick);
        });
      });
    },

    async winnerTender(isWinnerBthSuccess, isWinnerModal) {
      if ((can('admin_choose_winner') || can('head_choose_winner')) && ['ConfirmITRP', 'ConfirmMainOffice'].includes(this.order.status)) {
        const responseNoActiveModal = await this.openModalNoActiveConnect();

        if (responseNoActiveModal === 'noActiveConnect') {
          this.isWinnerModal = true;

          if (isWinnerBthSuccess) {
            if (!confirm("Ви справді бажаєте вибрати даного переможця?")) {
              return;
            }
            this.isWinnerModal = isWinnerModal;
            if (this.$store.state.login_info.roles.ac) {
              this.setITRPProposal()
            }
          }
        }
        console.log('noActiveConnectBack')

        return;
      }
      if (can('procurement_department_choose_winner') || can('admin_choose_winner') && ['ConfirmManager'].includes(this.order.status)) {
        this.setManagerProposalModal = !this.setManagerProposalModal;
      }
    },

    setITRPProposal() {
      let self = this;
      let axios_send = '';

      if (!self.comment) {
        return this.err.push('Заповніть поле із коментарем!')
      }

      if (this.order.status === 'ConfirmITRP') {
        axios_send = `/api/orders/${self.$route.params.id}/offers/proposal/winner`
      }
      if (this.order.status === 'ConfirmMainOffice') {
        axios_send = `/api/orders/${self.$route.params.id}/offers/main/proposal/winner`
      }


      axios.post(axios_send, {
        success: true,
        comment: self.comment,
        offer_id: self.offers_id
      })
          .then(function () {
            self.getTenderByID();
            self.isWinnerModal = false;
            self.comment = "";
            self.$router.push({path: `/tenders/${self.$route.params.id}`});
          })
          .catch(function () {
            self.err.push(`Сталася помилка, повторіть дію пізніше`)
            setTimeout(() => {
              self.isWinnerModal = false;
              self.comment = "";
              self.err = [];
            }, 2000);

          })
    },

    setManagerProposal() {
      let self = this;
      self.loadingsetManagerProposal = true;
      if (!self.comment) {
        return this.err.push('Заповніть поле із коментарем!')
      }
      self.comment = encodeURIComponent(self.comment)
      axios.post(`/api/orders/${self.$route.params.id}/offers/${self.offers_id}/winner?comment=${self.comment}`)
          .then(function () {
            self.isWinnerModal = false;
            self.comment = "";
            self.loadingsetManagerProposal = false;
            self.$router.push({path: `/tenders/${self.$route.params.id}`});
          })
          .catch(function () {
            self.err.push(`Сталася помилка, повторіть дію пізніше`);
            setTimeout(() => {
              self.isWinnerModal = false;
              self.comment = "";
            }, 2000);

            // self.loadingsetManagerProposal=false;
          })
    },

    getCommentITRP() {
      let self = this;
      axios.get(`/api/orders/${this.$route.params.id}/comments/itrp`).then(function (response) {
        self.commentITRP = response.data;

      }).catch(function () {
      });
    },

    getCommentMAIN() {
      let self = this;
      axios.get(`/api/orders/${this.$route.params.id}/comments/main`).then(function (response) {
        self.commentMAIN = response.data;

      }).catch(function () {
      });
    },

    getCommentDepartment() {
      let self = this;
      axios.get(`/api/orders/${this.$route.params.id}/comments/remark`).then(function (response) {
        self.commentDepartment = response.data;

      }).catch(function () {
      });
    },

    blockBTN() {
      if (['ConfirmITRP', 'ConfirmMainOffice'].includes(this.order.status) &&
          (
              this.$store.state.login_info.roles.ac ||
              this.$store.state.login_info.roles.ad ||
              this.$store.state.login_info.roles.za ||
              this.$store.state.login_info.roles.zm
          )
      ) {
        if (this.$store.state.login_info.roles.ac) {
          this.disabledWinnerBtn = false;
          this.is_show_feilds_Win = true;
        }
        this.getCommentITRP();
        this.getCommentMAIN();
        this.getCommentDepartment();
        return;
      }
      if (['ConfirmManager'].includes(this.order.status) &&
          (this.$store.state.login_info.roles.ad || this.$store.state.login_info.roles.za || this.$store.state.login_info.roles.zm)) {
        this.disabledWinnerBtn = false;
        this.is_show_feilds_Win = true;
        this.getCommentITRP();
        this.getCommentMAIN();
        this.getCommentDepartment();
        return;
      }
      if (['CloseWithResult'].includes(this.order.status) &&
          (this.$store.state.login_info.roles.ad || this.$store.state.login_info.roles.za || this.$store.state.login_info.roles.zm)) {
        this.disabledWinnerBtn = true;
        this.is_show_feilds_Win = false;
        return;
      }
      this.is_show_feilds_Win = false;
      this.disabledWinnerBtn = true;
    },
  },
  created() {
    this.getAllOffersByOrderID();
    this.getTenderByID();
    this.blockBTN();
  },
  watch: {
    'order.status': function () {
      return this.blockBTN();
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-content {
  .modal-footer {
    justify-content: space-between;
  }
}

</style>
